<template>
  <div class="home">
    <div class="aside">
      <div class="projectName">
        <h3>项目总览</h3>
        <!-- <el-input placeholder="请输入项目名称" v-model="keywords" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input> -->
      </div>
      <div class="projectList">
        <div class="add_project card" @click="add_project">
          <i class="el-icon-plus" size="small"></i>
          <p>点击创建新的直播项目</p>
        </div>
        <div
          class="project_list card"
          v-for="(item, index) in proList"
          :key="index"
        >
          <div class="list">
            <div class="list_name">
              <div class="title">
                <img :src="item.image_url || defaultImg" alt="" />

                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.project_name"
                  placement="top-start"
                >
                  <span>{{ item.project_name }}</span>
                </el-tooltip>
              </div>
              <div class="t1 urls">
                <span class="s1">直播地址:</span
                ><span class="s2">{{ item.bind_url }}</span>
              </div>
            
              <div class="t1 urls">
                <span class="s1">绑定账号:</span
                ><span class="s2">{{ item.bind_username }}</span>
              </div>
              <div class="t1 urls">
                <span class="s1">互动话术:</span
                ><span class="s2">{{ item.sku_url }}</span>
              </div>
              <div class="t1 urls">
                <span class="s1">备注:</span
                ><span class="s2">{{ item.remark }}</span>
              </div>
            </div>

            <div class="setting">
              <el-dropdown
                @command="set_project($event, item.id, item.project_name)"
              >
                <span
                  class="el-dropdown-link"
                  style="
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: #8ab4ff;
                  "
                >
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="2">分享</el-dropdown-item>
                  <el-dropdown-item command="3">分享码</el-dropdown-item>
                  <el-dropdown-item command="1">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div class="btn1">
                <el-button
                  size="small"
                  type="info"
                  plain
                  @click="edit_project(item)"
                  >编辑</el-button
                >

                <el-button
                  size="small"
                  style="background-color: black; color: #fff"
                  @click="view_project(item.id, item.project_name)"
                  >查看</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project_page" v-if="pageinfo.total > 10">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="pageinfo.total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <ProjectInfo
      @open="open"
      v-if="drawer"
      :drawer="drawer"
      :id="projectInfo.id"
      :project_name="projectInfo.name"
    >
    </ProjectInfo>

    <el-dialog
      title="请输入项目名称"
      :modal-append-to-body="false"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div class="addProject">
        <el-form ref="form" :model="fromInfo" label-width="80px">
          <el-input
            v-model="fromInfo.project_name"
            :maxlength="20"
            placeholder="请输入项目名称"
          >
            <template slot="prepend">项目名称</template>
          </el-input>
          <el-input
            v-model="fromInfo.bind_url"
            placeholder="请输入直播间地址"
            style="margin-top: 10px"
            :maxlength="200"
          >
            <template slot="prepend">直播地址</template>
          </el-input>
          <el-input
            v-model="fromInfo.bind_username"
            placeholder="请输入绑定账号"
            style="margin-top: 10px"
            :maxlength="200"
          >
            <template slot="prepend">绑定账号</template>
          </el-input>

          <el-input
            v-model="fromInfo.remark"
            placeholder="请输入备注"
            style="margin-top: 10px"
            :maxlength="500"
          >
            <template slot="prepend">项目备注</template>
          </el-input>
          <p class="mt10">直播互动话术（回车换行,单行限制40字以内）</p>
          <el-input
            v-model="fromInfo.sku_url"
            placeholder="请输入直播互动话术（回车换行）"
            style="margin-top: 10px"
            :maxlength="500"
            type="textarea"
            :rows="8"
          > </el-input>
          <div class="uploadimg" style="margin-top: 10px">
            <el-button type="info" plain @click="imgUpload"
              >上传图片</el-button
            >
            <input
              class="img"
              @change="imgUpload"
              type="file"
              accept="image/*"
            />
            <div class="showimg" v-if="fromInfo.image_url">
              <i
                class="el-icon-circle-close"
                @click="fromInfo.image_url = ''"
              ></i>
              <img :src="fromInfo.image_url" alt="" />
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="info"
          @click="submit_add"
          style="background-color: black; color: #fff"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="分享项目"
      :modal-append-to-body="false"
      :visible.sync="shareProjectShow"
      width="500px"
    >
      <el-form
        ref="form"
        label-position="left"
        :model="newProjectData"
        label-width="120px"
      >
        <el-form-item label="分享码">
          <el-input v-model="newProjectData.uuid"></el-input>
        </el-form-item>
        <el-form-item label="新建项目">
          <el-radio-group v-model="newProjectData.is_new">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <p style="color: red">* 否表示将目标项目复制到当前选中项目</p>
        </el-form-item>
        <!-- <el-form-item label="复制版本">
          <el-radio-group v-model="newProjectData.temp">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="复制模块">
          <el-radio-group v-model="newProjectData.script">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="复制关键词">
          <el-radio-group v-model="newProjectData.key">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="复制快捷回复">
          <el-radio-group v-model="newProjectData.quick">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-button
          :disabled="isSubmit"
          style="width: 100%; background-color: #000; color: #fff"
          @click="onsubmit"
          >提交</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  creatProject,
  projectList,
  deleteProject,
  editProject,
  getVariablesList,
  getShareCode,
  uploadFile,
  shareProject,
} from "@/api/index"; 
import ProjectInfo from "@/views/project_edit.vue";
export default {
  name: "HomeView",
  components: {
    ProjectInfo,
  },
  data() {
    return {
      defaultImg: require("@/assets/1.png"),
      drawer: false,
      projectStu: true,
      proList: [],
      dialogVisible: false,
      project_id: "",
      keywords: "",
      pageinfo: {
        page: 1,
        total: 0,
      },
      projectInfo: {
        id: "",
        name: "",
      },
      files: "",
      fromInfo: {
        project_name: "",
        bind_url: "",
        bind_username: "",
        remark: "",
        sku_url: "",
        image_url: "",
      },
      shareProjectShow: false,
      newProjectData: {
        uuid: "", //分享码
        project_id: "", //项目id
        temp: 0, //是否复制版本
        script: 1, //是否复制模块
        key: 1, //是否复制关键词
        quick: 1, //是否复制快捷回复
        is_new: true,
      },
      isSubmit: false,
    };
  },
  async mounted() {
    this.getList(); 
    if (this.$store.state.systemKeyword.length == 0) {
      getVariablesList().then((res) => {
        this.$store.commit("setSystemKeyword", res.data.list);
      });
    }
  },
  methods: {
  
    async imgUpload(e) {
      let files = e.target.files[0];
      const formData = new FormData();
      formData.append("file", files);
      formData.append("type", "image");
      const res = await uploadFile(formData);
      this.fromInfo.image_url = res.data.thumbnail;
      if (res.state == 1) this.$message.success("上传成功");
    },
    open() {
      this.drawer = false;
    },
    search() {},
    handleCurrentChange(e) {
      this.pageinfo.page = e;
      this.getList();
    },
    edit_project(item) {
      this.fromInfo = JSON.parse(JSON.stringify(item));
      this.project_id = item.id;
      this.dialogVisible = true;
    },
    // 获取项目列表
    async getList() {
      const res = await projectList(this.pageinfo.page);
      this.proList = res.data.list;
      this.pageinfo.total = res.data.total;
    },
    add_project() {
      this.fromInfo = {
        project_name: "",
        bind_url: "",
        bind_username: "",
        remark: "",
        sku_url: "",
        image_url: "",
      };
      this.project_id = "";
      this.dialogVisible = true;
    },
    // 添加项目
    async submit_add() {
      let { project_name } = this.fromInfo;
      if (!project_name) return this.$message("请输入项目名称");
      if (this.project_id) {
        const res = await editProject(this.project_id, this.fromInfo);
        if (res.state == 1) {
          this.getList();
        }
      } else {
        const res = await creatProject(this.fromInfo);
        if (res.state == 1) {
          this.$message(res.message);
          this.getList();
        }
      }
      this.dialogVisible = false;
    },
    view_project(id, name) {
      this.projectInfo = {
        id,
        name,
      };
      this.drawer = true;
    },
    // 项目操作 e = 1 删除 = 2 分享 = 3 获取分享码
    async set_project(e, id, name) {
      const that = this;
      if (e == 1) {
        this.$prompt(
          `此操作将永久删除【${name}】项目，请输入【确认删除】`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
            closeOnPressEscape: false,
            inputErrorMessage: "请输入【确认删除】四个字",
            inputValidator: function (value) {
              if (value != "确认删除") {
                return false;
              }
            },
          }
        )
          .then(async ({ value }) => {
            const res = await deleteProject(id);
            if (res.state == 1) {
              that.getList();
            }
            return false;
          })
          .catch(() => {});
      } else if (e == 2) {
        this.newProjectData = {
          uuid: "", //分享码
          project_id: id, //项目id
          temp: 0, //是否复制版本
          script: 1, //是否复制模块
          key: 1, //是否复制关键词
          quick: 1, //是否复制快捷回复
          is_new: true,
        };
        this.shareProjectShow = true;
      } else if (e == 3) {
        const code = await this.get_share_code(id);
        this.$alert(`分享码：${code}`, `分享码只能使用一次，每次重新生成`, {
          confirmButtonText: "确认",
          // callback: (action) => {
          //   if(this.code == ''){ // 内容为空时提醒用户
          //     this.$message.warning('没有可复制的内容！')
          //     return
          //   }
          //   const input = document.createElement('input') // 创建一个input
          //   document.body.appendChild(input) // 将新节点添加到末尾
          //   input.value = code // 需要复制的元素赋值给input
          //   input.select() // 选中input元素
          //   document.execCommand('Copy') // 复制
          //   document.body.removeChild(input) // 删除input
          //   this.$message.success('复制成功') // 提醒用户复制成功
          // },
        });
      }
    },
    // 获取分享码
    async get_share_code(id) {
      const res = await getShareCode(id);
      if (res.state == 1) {
        return res.data;
      } else {
        return false;
      }
    },
    // 分享项目
    async onsubmit() {
      let data = JSON.parse(JSON.stringify(this.newProjectData));
      let id = data.project_id;
      if (data.uuid == "") return this.$message.error("请填写分享码");
      this.isSubmit = true;
      if (data.is_new) {
        delete data.project_id;
      }
      delete data.is_new;
      const res = await shareProject(data);
      this.$message(res.data);
      this.isSubmit = false;
      if (res.state == 1) {
        this.shareProjectShow = false;
        this.pageinfo.page = 1;
        this.getList();
        this.newProjectData = {
          uuid: "", //分享码
          project_id: "", //项目id
          temp: 0, //是否复制版本
          script: 1, //是否复制模块
          key: 1, //是否复制关键词
          quick: 1, //是否复制快捷回复
          is_new: true,
        };
      } else {
        this.newProjectData.project_id = id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  background-color: $bgc;

  .addProject {
    .uploadimg {
      position: relative;
      display: flex;

      .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 126px;
        height: 40px;
        opacity: 0;
        z-index: 99;
      }

      .showimg {
        position: relative;

        i {
          position: absolute;
          right: -12px;
          top: -8px;
          color: #101010;
          background: #fff;
          border-radius: 50%;
          font-size: 20px;
        }

        img {
          width: 40px;
          height: 40px;
          margin-left: 20px;
        }
      }
    }
  }

  .aside {
    flex: 1;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .projectName {
      background-color: #fff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-weight: 800;
        font-size: 17px;
      }

      .input-with-select {
        width: 400px;
      }
    }

    .projectList {
      background-color: #fff;
      padding: 10px;
      flex: 1;
    }

    .project_page {
      background-color: #fff;
      padding: 10px;
    }

    .card {
      width: 305px;
      height: 200px;
      border-radius: 4px;
      float: left;
      background-color: #fff;
      margin: 0 10px 10px 0;
      border: 1px solid $bgc;
      box-shadow: 0 0 8px $bgc;
    }

    .add_project {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;

      p {
        margin-top: 15px;
        font-size: 16px;
      }
    }

    .project_list {
      .title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .list {
        padding: 10px;
        border: 1px solid $dk;
        border-radius: 3px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .t1 {
          color: #86909c;
        }

        .urls {
          margin: 10px 0;
        }

        .list_name {
          // align-items: center;
          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .title {
            font-weight: 800;
            font-size: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          > div {
            display: flex;

            .s1 {
              display: inline-block;
              width: 60px;
            }

            .s2 {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .setting {
          display: flex;

          .btn1 {
            flex: 1;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .main {
    padding: 20px;
    width: 300px;
    line-height: 40px;
    font-size: 18px;
  }
}
</style>
