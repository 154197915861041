<template>
  <div id="keywords">
    <el-dropdown class="mr10" @command="handleClick">
      <el-button :size="small">
        系统变量<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="item" v-for="(item, index) in systemKeyword" :key="index">{{
          item.text
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
  props:{
    small:{
      default:'',
      type:String
    }
  },
  data() {
    return {};
  },
  computed:{
        ...mapState(['systemKeyword'])
    }, 
  mounted() { 
  },
  methods: {
    handleClick(item){ 
        this.$emit('handleClick',item)
    }
  },
};
</script>
<style lang="scss" scoped>
        #keywords{
            display: inline-block;
        }
</style>
 