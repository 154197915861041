import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/project_edit',
    name: 'project_edit', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/project_edit.vue')
  },
  {
    path: '/audio',
    name: 'audio', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/audioSetting.vue')
  },
  {
    path: '/ai',
    name: 'ai', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/ai.vue')
  },
  {
    path: '/setting',
    name: 'setting', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/setting.vue')
  },
  {
    path: '/utils',
    name: 'utils', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/utils.vue')
  },
  {
    path: '/class',
    name: 'class', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/class.vue')
  },
  {
    path: '/audioCustom',
    name: 'audioCustom', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/audioCustom.vue')
  },
  {
    path: '/virtualCharacter',
    name: 'virtualCharacter', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/virtualCharacter.vue')
  },
  {
    path: '/systemSetting',
    name: 'systemSetting', 
    component: () => import(/* webpackChunkName: "about" */ '@/views/systemSetting.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
