<template>
  <div id="handbook">
    <el-dialog
      title="规则指南"
      :modal="false"
      :visible.sync="handbookShow"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
    <div>
        <h2>一、脚本相关</h2>
        <p> 1、{模块名称} 会自动匹配对应的模块内容 </p> 
      <p> 2、[变量名称] 会自动匹配对应的系统变量 </p> 
      <p> 3、[宝宝|大哥|大姐] 如果有 | 符号则随机匹配一个，不支持变量随机匹配，例:[当前时间|用户名称] </p> 
      <p> 4、除脚本输入框外其他地方禁止使用 { } 进行多层模块嵌套</p> 
      <p style="color: red;">* 自定义文本中请勿出现 {} | [] 相关符号</p>
    </div>
   
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
        handbookShow:false
    };
  },
  watch:{
    '$store.state.handbookShow':function (n,o){ 
        this.handbookShow = n
    }
  },
  mounted() {},
  methods: {
    handleClose(){
        this.handbookShow = false
        this.$store.commit('setHandbookShow',false)
    }
  },
};
</script>
<style lang='scss' scoped>
p{
    margin: 5px 0;
}
</style>