<template>
  <div id="ai">
    <div class="aside">
      <p
        :class="{ active: index == active }"
        v-for="(item, index) in dialogueList"
        :key="index"
      >
        <span @click="activeChecked(index)">会话{{ index + 1 }} </span>
        <i
          v-if="dialogueList.length > 1"
          class="el-icon-delete"
          @click="delLog(index)"
        ></i>
      </p>
      <p @click="add">+ 新增会话</p>
    </div>
    <div class="main" ref="main">
      <div class="list" ref="list">
        <div class="item">
          <img class="left" src="@/assets/3.png" alt="" />
          <p class="left">您好，我是智能ai助理，有什么可以帮到您的吗？</p>
        </div>
        <div class="item" v-for="(item, index) in history" :key="index">
          <img
            v-if="item.role == 'user'"
            :class="item.role == 'user' ? 'right' : 'left'"
            src="@/assets/2.png"
            alt=""
          />
          <img
            v-else
            :class="item.role == 'user' ? 'right' : 'left'"
            src="@/assets/3.png"
            alt=""
          />
          <p :class="item.role == 'user' ? 'right' : 'left'">
            {{ item.content }}
          </p>
        </div>
      </div>
      <div class="setting">
        <el-input
          placeholder="请输入对话"
          type="textarea"
          :rows="5"
          v-model="message"
          resize="none"
        ></el-input>
        <!-- <el-button type="info" plain>上传文件</el-button> -->
        <el-button class="send" type="info" @click="send" :disabled="getmsg"
          ><i v-if="getmsg" class="el-icon-loading"></i>
          {{ !getmsg ? "发送" : "等待回答" }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script> 
import { setLocalStorage, getLocalStorage } from "@/utils/storage";
export default {
  data() {
    return {
      active: 0,
      message: "",
      dialogueList: [],
      history: [],
      getmsg: false
    };
  },
  mounted() {
    let log = getLocalStorage("log");
    if (log) {
      this.dialogueList = log;
      this.history = log[0].history;
      this.$nextTick(() => {
            this.$refs.list.scrollTop = this.$refs.list.scrollHeight;
      });
    } else {
      this.dialogueList.push({
        conversation_id: this.getDate(),
        history: [],
      });
      setLocalStorage("log", this.dialogueList);
    }
  },
  methods: {
    delLog(index) {
      this.active = 0;
      this.dialogueList.splice(index, 1);
      this.history = this.dialogueList[0].history;
      setLocalStorage("log", this.dialogueList);
    },
    add() {
      this.dialogueList.push({
        conversation_id: this.getDate(),
        history: [],
      });
      setLocalStorage("log", this.dialogueList);
    },
    getDate() {
      return String(Date.now());
    },
    async send() {
      let fromData = this.dialogueList[this.active];
      let text = this.message;
      if (!text) return this.$message("请输入问题");
      fromData.history.push({
        role: "user",
        content: text,
      });
      this.history = fromData.history;
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight;
      });
      this.message = ""; 
      let len = fromData.history.length; 
      this.getmsg = true
      const resp = await fetch('http://chat.izhibo.top/chat/chat',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: text,
          conversation_id: fromData.conversation_id,
          history_len: -1,
          history: fromData.history,
          stream: true,
          model_name: "qwen-api",
          temperature: 0.7,
          max_tokens: 0,
          prompt_name: "default",
        })
      }) 
      try{
        const reader = resp.body.getReader()
        const decoder = new TextDecoder()  
        fromData.history.push({
          role: "assistant",
          content: '',
        })
        while(1){
          const {done ,value} = await reader.read()
          if(done){
            break;
          }
          const str =  decoder.decode(value)  
          fromData.history[len].content += JSON.parse(str).text
          this.$nextTick(() => {
            this.$refs.list.scrollTop = this.$refs.list.scrollHeight;
          });
        }
        this.getmsg = false
        this.dialogueList[this.active] = fromData;
        setLocalStorage("log", this.dialogueList); 
      
      }catch{
        this.getmsg = false
      } 
      // return 
      // aiDialogue({
      //   query: text,
      //   conversation_id: fromData.conversation_id,
      //   history_len: -1,
      //   history: fromData.history,
      //   stream: true,
      //   model_name: "qwen-api",
      //   temperature: 0.7,
      //   max_tokens: 0,
      //   prompt_name: "default",
      // })
      //   .then((res) => { 
         
      //     let data = JSON.parse("[" + res.replace(/}{/g, "},{") + "]");

      //     let content = "";
      //     for (let i in data) {
      //       content += data[i].text;
      //     }
      //     fromData.history.push({
      //       role: "assistant",
      //       content: content,
      //     });
      //     that.history = fromData.history;
      //     that.dialogueList[that.active] = fromData;
      //     setLocalStorage("log", that.dialogueList);
      //     that.$nextTick(() => {
      //       that.$refs.list.scrollTop = that.$refs.list.scrollHeight;
      //     });
      //     that.getmsg = false;
      //   })
      //   .catch((err) => {
      //     that.getmsg = false;
      //   });
    },

    activeChecked(index) {
      this.active = index;
      this.history = this.dialogueList[index].history;
    },
  },
};
</script>
<style lang='scss' scoped>
#ai {
  display: flex;
  height: 100%;

  .aside {
    width: 150px;
    background-color: #f1f1f1;
    padding: 10px;
    position: relative;
    .el-icon-delete {
      position: absolute;
      right: 19px;
      top: 15px;
      font-size: 20px;
      z-index: 10;
    }
    .active {
      background-color: #000;
      color: #fff;
    }

    p {
      text-align: center;
      background-color: #fff;
      margin-bottom: 5px;
      border-radius: 5px;
      position: relative;
      height: 47px;
      line-height: 47px;
      &:hover {
        cursor: pointer;
      }
      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        line-height: 47px;
      }
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;

    .list {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 10px;

      .item {
        padding: 20px 0;
        overflow: hidden;

        .left {
          float: left;
        }

        .right {
          float: right;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 0 10px;
        }

        p {
          background-color: #f1f1f1;
          padding: 10px 20px;
          border-radius: 5px;
          max-width: 500px;
          line-height: 30px;
        }
      }
    }

    .setting {
      .send {
        background-color: #000;
        color: #fff;
        width: 200px;
        margin: 10px 0;
      }
    }
  }
}
</style>