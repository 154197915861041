export const getLocalStorage = name => {

  return JSON.parse(localStorage.getItem(name));
};

export const delLocalStorage = name => {
  return localStorage.removeItem(name);
};

export const setLocalStorage = (name, content) => { 
  return localStorage.setItem(name, JSON.stringify(content));
};
