import store from '@/store';

/**
 * 
 * @param {返回时间格式} e
 */
export function gettime(e) {
	let time = new Date(e);
	var y = time.getFullYear();
	var m = time.getMonth() + 1;
	var d = time.getDate();
	var h = time.getHours();
	var mm = time.getMinutes();
	var s = time.getSeconds();

	return add(m) + "-" + add(d) + " " + add(h) + ":" + add(mm) + ":" + add(s);
	// return y + "-" + add(m) + "-" + add(d) + " " + add(h) + ":" + add(mm) + ":" + add(s);
}


function add(m) {
	return m < 10 ? "0" + m : m;
}

// 获取当前时间时分
export function getTime_hours_month(e) {
	let now = new Date();
	let h = now.getHours();
	let m = now.getMinutes();
	return `${h}点${m}分`
}
// 获取下个整点
export function getTime_hours(e) {
	const now = new Date();
	const hours = now.getHours();
	const minutes = now.getMinutes();
	let date = ''
	// 如果分钟小于或等于30，则直接设置时间为下一个小时  
	if (minutes <= 30) {
		date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours + 1);
	} else { // 否则，将分钟设置为0并设置时间为下一个小时  
		date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours + 1, 0);
	}
	return `${date.getHours()}点`;
}
// 获取距离下个整点还有多少分钟
export function getTime_now_month() {
	let now = new Date();
	let nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
	let currentMinutes = now.getMinutes();
	let nextHourMinutes = nextHour.getMinutes();
	let minutesDiff = (nextHourMinutes - currentMinutes + (nextHour.getMinutes() < currentMinutes ? 60 : 0)) % 60;
	let m = minutesDiff === 0 ? 60 : minutesDiff;
	return `${m}分钟`
}

export function getMinutesUntilNextTenth() {
	let now = new Date();
	let minutes = now.getMinutes();
	let remainder = minutes % 10;
	let minutesUntilNextTenth = 10 - remainder;
	let m = minutesUntilNextTenth === 10 ? 10 : minutesUntilNextTenth;
	return `${m}分钟`
}

// 判定是否含有[]为系统变量需要对应的转换
export function textformat(text) {
	// 去掉换行符
	let sd = text.replace(/\n/g, '');
	// 转换格式  匹配系统变量 
	let regex = /\[(.*?)\]|(.*?)(?=\[|$)/g;
	let result = sd.match(regex).map(s => s.includes('|') ? s : s.replace(/\[|\]/g, ''));

	// let regex = /(\[.*?\])/g
	// let data = sd.split(regex) 
	// let result = data.map(s => s.includes('|') ? s : s.replace(/\[|\]/g, ''));  
	// let result = text.match(regex).map(s => s.replace(/\[|\]/g, ''));
	let words = store.state.systemKeyword
	let str = ''
	for (let i in result) {
		let n = words.filter((item) => {
			return result[i] == item.text
		})
		if (n.length > 0) {
			str += n[0].run()
		} else {
			str += result[i]
		}
	}
	let t = randomMatching(str) 
	return t
	// return str
}
// 判定是否含有[]并且含有|，有|随机读取一个
function randomMatching(text) {
	let regex = /\[(.*?)\]|(.*?)(?=\[|$)/g;
	let result = text.match(regex).map(s => s.replace(/\[|\]/g, ''));
	result = result.filter(item => item != '')
	for (let i in result) {
		result[i] = result[i].split('|')
	}
	let str = ''
	for (let j in result) {
		if (result[j].length > 1) {
			let n = getRandomInt(0, result[j].length - 1)
			str += result[j][n]
		} else {
			str += result[j]
		}
	}
	return str
}

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}