import { render, staticRenderFns } from "./handbook.vue?vue&type=template&id=36b468a2&scoped=true"
import script from "./handbook.vue?vue&type=script&lang=js"
export * from "./handbook.vue?vue&type=script&lang=js"
import style0 from "./handbook.vue?vue&type=style&index=0&id=36b468a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b468a2",
  null
  
)

export default component.exports