<template>
  <div id="login">
    <div class="bgc_logo"></div>
    <div class="right">
      <div class="logo">
        <i class="img">
          <img class="p1" src="@/assets/p1.png" alt="" />
        </i>
        <h1>AI直播</h1>
      </div>
      <div class="t1">Hi,欢迎使用账号登录系统</div>
      <div class="t2">请使用开发商绑定的账号密码进行登录</div>

      <el-tabs v-model="activeName">
        <el-tab-pane label="登陆" name="first">
          <el-input
            class="input1"
            @keyup.enter.native="userLogin"
            placeholder="请输入您的账号"
            v-model.trim="username"
          >
            <template slot="prepend">账号:</template>
          </el-input>
          <br />
          <el-input
            class="input1 mt20"
            @keyup.enter.native="userLogin"
            placeholder="请输入您的密码"
            v-model.trim="password"
            type="password"
          >
            <template slot="prepend">密码:</template></el-input
          >
          <br />
          <el-button
            :loading="loginAbled"
            class="login_button"
            type="info"
            @click="userLogin"
            >登录</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="注册" name="second">
          <el-input
            class="input1"
            placeholder="账号"
            v-model.trim="register.username"
          >
            <template slot="prepend">账号:</template></el-input
          >
          <br />
          <el-input
            class="input1 mt20"
            placeholder="密码"
            v-model.trim="register.password"
            type="password"
          >
            <template slot="prepend">密码:</template></el-input
          >
          <br />
          <el-input
            class="input1 mt20"
            placeholder="卡密"
            v-model.trim="register.code"
          >
            <template slot="prepend">卡密:</template></el-input
          >
          <br />
          <el-button
            class="login_button"
            type="info"
            @click="registerUser"
            :loading="isregister"
            :disabled="isregister"
            >注册</el-button
          >
          <p class="mt10">* 本地端无法续费为云端，需要云端权限请联系客服</p>
        </el-tab-pane>
        <el-tab-pane label="续费" name="third">
          <el-input
            class="input1"
            placeholder="请输入您的账号"
            v-model.trim="username"
          >
            <template slot="prepend">账号:</template></el-input
          >
          <br />
          <el-input
            class="input1 mt20"
            placeholder="卡密"
            v-model.trim="register.code"
          >
            <template slot="prepend">卡密:</template></el-input
          >
          <br />
          <el-button
            :loading="isRenew"
            class="login_button"
            type="info"
            @click="renew"
            >续费</el-button
          >
          <p class="mt10">* 本地端无法续费为云端，需要云端权限请联系客服</p>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { login, registerUser, editVipCode } from "@/api/index";
import { setLocalStorage } from "@/utils/storage";
export default {
  data() {
    return {
      activeName: "first",
      username: "",
      password: "",
      isregister: false,
      loginAbled: false,
      registerAbled: false,
      register: {
        username: "",
        password: "",
        code: "",
      },
      isRenew: false,
      // username: 'km10002',
      // password: 'makuai1129'
    };
  },
  mounted() {},
  methods: {
    renew() {
      this.isRenew = true;
      editVipCode({ code: this.register.code, username: this.username })
        .then((res) => {
          this.isRenew = false;
          if (res.state == 1) {
            this.$message.success(res.data);
            this.register.code = "";
            this.username = "";
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.isRenew = false;
          this.$message.error(res.message);
        });
    },
    async registerUser(e) {
      if (!this.register.username || !this.register.password) {
        return this.$message("请输入账号和密码");
      }
      if (!this.register.code) {
        return this.$message("请输入卡密");
      }
      this.isregister = true;

      const res = await registerUser(this.register);
      if (res.state == 1) {
        this.$message(res.data);
        this.isregister = false;
        return;
      } else {
        this.$message.error(res.message);
      }
      this.isregister = false;
    },
    userLogin(e) {
      if (!this.username || !this.password) {
        return this.$message("请输入账号和密码");
      }
      this.loginAbled = true;
      login({
        username: this.username,
        password: this.password,
      })
        .then((res) => {
          if (res.state == 1) {
            this.$store.state.token = res.data.token;
            this.$store.state.userInfo = res.data;
            setLocalStorage("token", res.data.token);
            setLocalStorage("userInfo", res.data);
            this.$store.commit("changeTabs", 0);
            this.$router.go(0);
          } else {
            this.$message.error(res.message);
          }
          this.loginAbled = false;
        })
        .catch((err) => {
          this.loginAbled = false;
        });
    },
  },
};
</script>
<style lang="scss">
#login {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  .bgc_logo {
    flex: 1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("@/assets/login.jpg");
  }
  .right {
    padding: 0 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .logo {
      display: flex;
      align-items: center;

      .img {
        background: linear-gradient(180deg, #c1559d 0%, #6730c7 99%);
        width: 48px;
        height: 48px;
        border-radius: 9px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .p1 {
          width: 30px;
          height: 30px;
        }
      }

      h1 {
        font-size: 40px;
        font-weight: 900;
        line-height: 72px;
        letter-spacing: 0em;
        color: #333333;
        margin-left: 20px;
      }
    }

    .t1 {
      margin-top: 50px;
      margin-bottom: 16px;
      font-size: 40px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;

      color: #333333;
    }

    .t2 {
      margin-bottom: 75px;

      font-family: 思源黑体;
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;

      color: #777777;
    }
    .input1 {
      width: 560px;
    }
    .register {
      display: inline-block;
      margin-top: 20px;
      color: #19adf1;
    }

    .login_button {
      margin-top: 20px;
      width: 560px;
      height: 60px;
      border-radius: 8px;
      background: #333333;
    }
  }
}
</style>
