import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import { getLocalStorage, setLocalStorage, delLocalStorage } from '@/utils/storage'
import {getTime_hours_month,getTime_hours,getTime_now_month,getMinutesUntilNextTenth} from '@/utils/util'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: 'http://www.izhibo.top',
    // baseURL: 'http://ai.quanmindaifa.com', 
    token: '',
    userInfo: '',
    // 是否显示声音列表弹窗
    AudioShow: false,
    // 选择的声音模型
    AudioInfo: '',
    // 声音模型列表
    AudioList: [],
    // 当前播放的文本  
    LisenText: '',
    // 播放状态
    audioPlayShow: false,
    // 左侧菜单
    tabsIndex: 0, 
    tabs:[
      {status:true, index:0,text:'项目总览',url:'/',icon:'el-icon-s-data'}, 
      {status:true,index:1,text:'弹幕设置',url:'/setting',icon:'el-icon-s-comment'}, 
      {status:true,index:2,text:'常用工具',url:'/utils',icon:'el-icon-menu'}, 
      // {status:false,index:3,text:'AI智能助手',url:'/ai',icon:'el-icon-chat-dot-round'},
      {status:true,index:3,text:'音频/文本处理',url:'/audio',icon:'el-icon-microphone'}, 
      {status:true,index:4,text:'声音试听/下载',url:'/virtualCharacter',icon:'el-icon-user'},  
      {status:true,index:5,text:'声音定制',url:'/audioCustom',icon:'el-icon-phone-outline'}, 
      {status:true,index:6,text:'教学案例',url:'/class',icon:'el-icon-video-camera-solid'}, 
      {status:true,index:7,text:'设置/续费',url:'/systemSetting',icon:'el-icon-setting'}, 
      
    ],
    systemKeyword:[],
    handbookShow:false,
    isWebSocket:false,//转换语音的websocket连接状态
  },
  getters: {

  },
  mutations: {
    setWebSocket(state,stu){
      state.isWebSocket = stu
    },
    setHandbookShow(state,data){
      state.handbookShow = data
    },
    // 匹配系统变量
    setSystemKeyword(state, data) {
      let res  = data
      for(let  i in res){
          if(res[i].text == '当前时间'){
            res[i].run = ()=>{ 
              return getTime_hours_month()
            }
          }else if(res[i].text == '下个整点'){
            res[i].run = ()=>{ 
              return getTime_hours()
            }
          }else if(res[i].text == '下个整点间隔'){
            res[i].run = ()=>{ 
              return getTime_now_month()
            }
          }else if(res[i].text == '下个十分钟间隔'){
            res[i].run = ()=>{ 
              return getMinutesUntilNextTenth()
            }
          }else{
            res[i].run = ()=>{ 
              return res[i].text
            }
          }
          // else if(res[i].text == '静音一秒'){
          //   res[i].run = ()=>{ 
          //     return 'stop'
          //   } 
          // }
      }
      state.systemKeyword = res 
    },
    // 左侧tab切换
    changeTabs(state, index) {
      state.tabsIndex = index  
      setLocalStorage('tabIndex', index)   
      router.push(state.tabs[index].url) 
    },
    // 更新播放状态
    AudioPlayShow(state, stu) {
      state.audioPlayShow = stu
    },
    // 更新播放文本
    setLisenText(state, data) {
      state.LisenText = data
    },
    // 更新声音模型列表
    setAudioList(state, data) {
      state.AudioList = data
      setLocalStorage('AudioList', data)
    },
    // 更新当前播放声音模型
    refreshAudioInfo(state, data) {
      state.AudioInfo = data
      setLocalStorage('AudioInfo', data)
    },
    //刷新页面更新缓存数据
    AllInRefresh(state) { 
      state.audioPlayShow = false
      state.AudioShow = false 
      state.handbookShow = false
      state.setLisenText = ''  
      router.push(state.tabs[state.tabsIndex].url) 
      var keys = Object.keys(localStorage);
      if (keys.length > 0) {
        for (let i in keys) {
          state[keys[i]] = getLocalStorage(keys[i])
        }
      } 
    },
    //退出登录清空数据
    loginOut(state) {
      state.AudioInfo = ''
      state.AudioList = ''
      state.token = ''
      state.userInfo = ''
      state.LisenText = '' 
      var keys = Object.keys(localStorage);
      if (keys.length > 0) {
        for (let i in keys) {
          delLocalStorage(keys[i])
        }
      }

    }
  },
  actions: {
  },
  modules: {
  }
})
