import { get, post, del, put } from '@/utils/axios'
// 登录
export function login(data) {
    return post('/v1/auth/login/', data)
}
//注册
export function registerUser(data) {
    return post('/v1/auth/register/', data)
}
// ---------------------------------------------------------------
// 删除项目
export function deleteProject(id) {
    return del(`/v1/template/project/delete/${id}/`);
}
// 项目列表
export function projectList(page) {
    return get(`/v1/template/project/list/?pageNo=${page}`)
}
// 创建项目
export function creatProject(data) {
    return post('/v1/template/project/create/', data)
}
// 编辑项目
export function editProject(id, data) {
    return put(`/v1/template/project/update/${id}/`, data)
}
//获取项目分享码
export function getShareCode(id) {
    return post(`/v1/template/share_code/create/`,{
        project_id:id
    })
}
//分享项目
export function shareProject(data) {
    return post(`/v1/template/copy_project/create/`,data)
}


// ---------------------------------------------------------------
//  创建模块
export function creatScript(data) {
    return post('/v1/template/script/create/', data)
}

// 模块列表
export function scriptList(data) {
    return get(`/v1/template/script/list/?project_id=${data.project_id}`)
}

// 获取模块详情
export function scriptInfo(id) {
    return get(`/v1/template/script/detail/?script_id=${id}`)
}

// 删除模块
export function scriptDel(id) {
    return del(`/v1/template/script/delete/${id}/`)
}

// 编辑模块
export function scriptEdit(data) {
    return post(`/v1/template/script/update/`, data)
}


// ---------------------------------------------------------------
// 创建脚本版本
export function creatProjectPersion(data) {
    return post('/v1/template/project_version/create/', data)
}

// 获取脚本版本详情
export function getVersionInfo(id) {
    return get(`/v1/template/project/get/${id}/`)
}

// 获取脚本版本详情
export function editVersionInfo(data) {
    return post(`/v1/template/project_version/update/`, data)
}

// 删除脚本
export function delVersion(id) {
    return del(`/v1/template/project_version/delete/${id}/`)
}

// 添加关键词
export function createKeywords(data) {
    return post(`/v1/template/keyword/create/`, data)
}
// 关键词列表
export function getkeywordList(id) {
    return get(`/v1/template/keyword/list/?project_id=${id}`)
}
// 编辑关键词
export function editkeywordList(data) {
    return post(`/v1/template/keyword/update/`, data)
}
// 删除关键词
export function delkeyword(id) {
    return del(`/v1/template/keyword/delete/${id}/`,)
}

// ---------------------------------------------------------------
// 快捷回复
// 添加
export function createQuick(data) {
    return post(`/v1/template/quick_replies/create/`, data)
}
// 列表
export function getQuickList(id) {
    return get(`/v1/template/quick_replies/list/?project_id=${id}`)
}
// 编辑
export function editQuick(data) {
    return post(`/v1/template/quick_replies/update/`, data)
}
// 删除
export function delQuick(id) {
    return del(`/v1/template/quick_replies/delete/${id}/`,)
}

// ---------------------------------------------------------------
 
// 获取语音模型列表
export function speechModel() {
    // return get('http://test.izhibo.top/models/info',{
    //     headers:{
    //         'Content-Type': 'application/json'
    //     }
    // })
    // return get('http://bert-edfdfixdcz.cn-hangzhou.fcapp.run/v1/template/speech_models/get/',{
    //     headers:{
    //         'Content-Type': 'application/json'
    //     }
    // })
    return get('/v1/template/speech_models/get/')

}  
// 文字转语音
export function uploadFiles(data){ 
    
    // return post('/v1/template/audition/', data,{
    //     headers:{
    //         'Content-Type': 'application/json'
    //     },  
    //     responseType:'blob'
    // })
    console.log(data);
    return post(`http://bert-edfdfixdcz.cn-hangzhou.fcapp.run/voice?model_id=${data.model_id}`, {text:data.text, auto_split:true},{
        // headers:{
        //     'Content-Type': 'application/json'
        // },  
        responseType:'blob'
    })
}


// 语音转文字
export function audioToText(data) {

    return post('/v1/template/mp3_to_text/', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}


//txt文件 转项目
export function txtToProject(data) {
    return post('/v1/template/auto_project/create/', data)    
}


//历史转文字列表
export function historyAudioToText() {  // 获取历史语音转文字列表数据 
    return get('/v1/template/conver_records/list/')
}
// 上传文件
export function uploadFile(data) {

    return post('/v1/file/upload/', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

// 智能重写
export function intelligenceRewrite(data) { 
    return post('/v1/template/services/aigc/', data)
}

// ---------------------------------------------------------------
// 添加弹幕后缀
// 添加
export function createSystemVariables(data) {
    return post(`/v1/template/barrage_suffix/create/`, data)
}
// 列表
export function getSystemVariables(id) {
    return get(`/v1/template/barrage_suffix/list/`)
}
// 编辑
export function editSystemVariables(id,data) {
    return put(`/v1/template/barrage_suffix/update/${id}/`, data)
}
// 删除
export function delSystemVariables(id) {
    return del(`/v1/template/barrage_suffix/delete/${id}/`,)
}

// ---------------------------------------------------------------
// 系统变量列表 
export function getVariablesList() {
    return get(`/v1/template/system_variables/list/`)
}

// 工具链接列表 
export function getUtilsList() {
    return get(`/v1/template/software_download/get/`)
}

// 修改密码 
export function editPassword(data) {
    return post(`/v1/auth/updatepwd/`,data)
}
// 续费 
export function editVipCode(data) {
    return post(`/v1/auth/add_time/`,data)
}