<template>
  <el-drawer
    :visible.sync="drawerStu"
    size="96%"
    :wrapperClosable="false"
    :modal="true"
    @close="drawerClose"
  >
    <div id="edit">
      <div class="main">
        <div class="func">
          <h2>
            <el-button size="small" @click="drawerClose">返回</el-button>
            <el-button
              type="primary"
              plain
              class="mr10"
              @click="refresh"
              size="small"
              icon="el-icon-refresh-left"
            ></el-button
            >项目名称：{{ project_name }}
          </h2>
          <div class="func_r">
            <div class="version">
              <h4>选择版本:</h4>
              <el-select
                style="width: 140px"
                @change="checkVersion"
                v-model="value1"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in versionData"
                  :key="index"
                  :label="item.template_version"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="version">
              <h4>选择主播:</h4>
              <el-select
                style="width: 140px"
                @change="checkAudioClick"
                v-model="value2"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in AudioList"
                  :key="index"
                  :label="item.name"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
        </div>

        <div>
          <div class="tabs">
            <div
              @click="activeClick(index)"
              :key="index"
              v-for="(item, index) in actives"
              :class="active == index ? 'active' : ''"
            >
              <div>{{ item.title }}</div>
              <div class="t2">{{ item.tips }}</div>
            </div>
          </div>
          <!-- 脚本内容 -->
          <div class="tabs_main" v-if="active == 0">
            <div class="script-s">
              <div class="script-main">
                <div class="script">
                  <el-button
                    @click="handbookShow"
                    icon="el-icon-warning"
                    type="warning"
                    plain
                    >使用规则</el-button
                  >
                  <div class="script_title">
                    <!-- <SystemKeyword @handleClick="handleClick"/> -->
                    <el-button
                      @click="addAllModel" 
                      style="margin-right: 10px"
                    >
                      添加所有模块
                    </el-button>
                    <el-select
                      style="width: 110px"
                      @change="command_script"
                      v-model="script_defualt_value"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in script_list"
                        :key="item.id"
                        :label="item.script_name"
                        :value="index"
                      >
                      </el-option>
                    </el-select>
                    <el-button
                      @click="auditionAudio(0)"
                      plain=""
                      icon="el-icon-document"
                      style="margin-left: 10px"
                    >
                      预生成脚本
                    </el-button>
                    <!-- <el-button
                      @click="auditionAudio(1)"
                      type="info"
                      icon="el-icon-headset"
                      style="
                        background-color: black;
                        color: #fff;
                        margin-left: 10px;
                      "
                    >
                      预览
                    </el-button> -->
                  </div>
                </div>

                <el-input
                  type="textarea"
                  style="font-size: 16px"
                  :rows="20"
                  v-model="entirety_script_handle"
                ></el-input>
                <!-- <div contenteditable="true" id="editableDiv" @input="script_html_text" >
                                    {{ entirety_script_handle }}
                                </div> -->
                <div
                  style="
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <el-button v-if="versionInfo" @click="edit_version_info">
                      保存编辑
                    </el-button>

                    <el-button
                      v-if="versionInfo"
                      type="danger"
                      icon="el-icon-delete"
                      plain
                      style="margin-left: 10px"
                      @click="delete_version"
                    >
                      删除版本
                    </el-button>
                  </div>
                  <div>
                    <el-input
                      placeholder="输入新版本名字"
                      v-model="version"
                      style="width: 200px; margin-right: 20px"
                      maxlength="20"
                    ></el-input>

                    <el-button type="primary" plain @click="submit_add_version">
                      提交新版本
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="tipHtml">
                <h3>预生成脚本文案</h3>
                <div
                  class="infinite-list"
                  style="overflow: scroll; height: 630px"
                >
                  <p class="infinite-list-item">{{ tipstext }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 话术模板 -->
          <div class="tabs_main" v-if="active == 1">
            <div class="mb10">
              <el-input
                @keyup.native="searchClick"
                placeholder="请输入要搜索的模块名称，或者内容包含的文字"
                v-model="searchText"
                class="input-with-select"
              >
              </el-input>
            </div>
            <div class="">
              <div class="add" @click="new_temp">
                <i class="el-icon-plus" size="small"></i>
              </div>
              <div
                class="temp_list" 
                v-for="(item, index) in script_list"
                v-if="!item.show"
                :key="index"
              >
                <div class="temp_title">
                  <h3>{{ item.script_name }}</h3>
                  <span class="ts">{{ item.is_replay ? "副播" : "主播" }}</span>
                </div>
                <p class="t3">
                  <i style="color: #ad26c5;font-size: 18px" v-if="getLength(item.green_path_list)" class="el-icon-video-camera-solid mr10"></i>
                  <i style="color: #f5534b;font-size: 18px" v-if="item.promotion_id" class="el-icon-s-goods"></i>
                  {{ JSON.parse(item.script_list)[0].template_content }}
                </p>
                <div class="setting">
                  <el-button
                    @click="delete_script(item.id)"
                    size="small"
                    type="danger"
                    plain
                    icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                  <el-button
                    size="small"
                    type="warning"
                    plain
                    icon="el-icon-edit"
                    @click="edit_project(index)"
                    >编辑</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 关键词 -->
          <div class="tabs_main" v-if="active == 2">
            <div>
              <div class="add" @click="keywordsVisibleShow">
                
                <i class="el-icon-plus" size="small"></i>
              </div>
              <div
                class="temp_list"
                v-for="(item, index) in keywordList"
                :key="index"
              >
                <h3> 
                  <i style="color: #ad26c5;font-size: 18px" v-if="getLength(item.green_path_list)" class="el-icon-video-camera-solid mr10"></i>
                  <i style="color: #f5534b;font-size: 18px" v-if="item.promotion_id" class="el-icon-s-goods"></i>
                  {{ item.keyword_name }}</h3>
                <p class="t3">
                  <el-tag
                    class="mr10 mb10"
                    type="info"
                    size="samll"
                    v-for="(vo, i) in JSON.parse(item.keyword_issue).splice(
                      0,
                      5
                    )"
                    :key="i"
                    >{{ vo }}</el-tag
                  >
                  <i v-if="JSON.parse(item.keyword_issue).length > 5">... </i>
                </p>

                <div class="setting">
                  <el-button
                    @click="delete_keywords(item.id)"
                    size="small"
                    type="danger"
                    plain
                    icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                  <div>
                    <el-button
                      type="info"
                      size="small"
                      plain
                      @click="synchronization(index)"
                      >同步</el-button
                    >
                    <el-button
                      type="warning"
                      size="small"
                      plain
                      icon="el-icon-edit"
                      @click="edit_keywords(index)"
                      >编辑</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 快捷回复列表 -->
          <div class="tabs_main" v-if="active == 3">
            <div>
              <div class="add" @click="quickVisibleShow">
                <i class="el-icon-plus" size="small"></i>
              </div>
              <div
                class="temp_list"
                v-for="(item, index) in quickList"
                :key="index"
              >
                <h3>
                  <i style="color: #ad26c5;font-size: 18px" v-if="getLength(item.green_path_list)" class="el-icon-video-camera-solid mr10"></i>
                  <i style="color: #f5534b;font-size: 18px" v-if="item.promotion_id" class="el-icon-s-goods"></i>
                  {{ item.quick_name }}</h3>
                <p class="t3">{{ JSON.parse(item.quick_content)[0] }}</p>
                <div class="setting">
                  <el-button
                    @click="delete_quick(item.id)"
                    size="small"
                    icon="el-icon-delete-solid"
                    type="danger"
                    plain
                    >删除</el-button
                  >
                  <el-button
                    type="warning"
                    plain
                    size="small"
                    icon="el-icon-edit"
                    @click="edit_quick(index)"
                    >编辑</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 模块添加 -->
      <el-dialog
        title="添加模块"
        :modal="false"
        :visible.sync="dialogVisible"
        width="80%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="edit_dialog">
          <div class="title"><i class="t">* </i>话术名称  </div>
          <el-input
            placeholder="请输入模块名称"
            v-model="script_name"
            maxlength="20" 
          ></el-input>
          <div class="title title1">
            <div><i class="t">* </i>话术脚本</div>
            <!-- <div>
              <el-select
                style="width: 150px"
                @change="checkAudioClick"
                v-model="value2"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in AudioList"
                  :key="index"
                  :label="item.name"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div
            class="del_content"
            v-for="(item, index) in sentence_list"
            :key="index"
          >
            <el-input
              placeholder="请输入话术, 最多500字"
              v-model="item.template_content"
              type="textarea"
              :rows="5"
              show-word-limit
              :maxlength="500" 
            ></el-input>
            <div class="mt10">
              <el-button
                type="success"
                size="small"
                plain
                @click="audition(item.template_content)"
                >预览</el-button
              >
              <el-button
                :disabled="is_intelligence"
                type="warning"
                size="small"
                plain
                @click="intelligence(index, 'sentence_list')"
                >智能重写</el-button
              >
            <!-- <SystemKeyword @handleClick="handleClick2($event,index)" small="small" class="ml10"/>  -->
              <el-button
                size="small"
                @click="sentence_list.splice(index, 1)"
                icon="el-icon-delete"
                v-if="index > 0"
                >删除</el-button
              >
            </div>
          </div>
          <el-button type="primary" plain class="add_text" @click="add_content"  size="small">新增一行话术</el-button>
          <div class="title"><i class="t"></i>绿幕视频地址（注意：电脑本地路径，使用双斜线\\分割，请手动更改） </div>
          <div style="display: flex" v-for="(item,g) in green_path_list" :key="g"    class="mb10">
            <el-button v-if="g > 0" @click="green_path_list.splice(g,1)" type="danger" icon="el-icon-delete" plain size="small"></el-button>
            <el-input
            placeholder="格式例如：C:\\Users\\Administrator\\Desktop\\绿幕.mp4"
            v-model="item.template_content"
            maxlength="100"   
          ></el-input> 
          </div>
          <el-button @click="green_path_list.push({template_content:''})" class="mb10" type="primary" icon="el-icon-plus" plain size="small">添加地址</el-button>
          <div class="title"><i class="t"></i>商品ID(前往客户端获取商品列表复制对应id) </div>
          <el-input
            placeholder=""
            v-model="promotion_id"
            maxlength="100"   
          ></el-input> 
          <p><i class="t">* </i>主播类型</p> 
          <div class="audio_check">
            <el-radio-group v-model="is_replay">
              <el-radio :label="0">主播</el-radio>
              <el-radio :label="1">副播</el-radio>
            </el-radio-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="info" @click="submit_add" size="small">
            确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 关键词添加 -->
      <el-dialog
        title="关键词"
        :modal="false"
        :visible.sync="keywordsVisible"
        width="80%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="keywords">
          <div><i class="t">* </i>模块名称</div>
          <div>
            <el-input
              placeholder="请输入模块名称"
              v-model="keywordInfo.keyword_name"
              :maxlength="20"
            ></el-input>
          </div>
          <div><i class="t">* </i>关键词 【按回车确认添加多个】</div>
          <div>
            <el-input
              placeholder="输入关键词"
              v-model="value"
              @keyup.enter.native="add_keys"
              :maxlength="20"
            ></el-input>
            <div class="mt10">
              <el-tag
                class="mr10 mb10"
                v-for="(tag, index) in keyword_issue"
                type="info"
                @close="keyword_issue.splice(index, 1)"
                :key="tag.name"
                closable
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
          <div>
            <i class="t">* </i>回复频率 【关键词出现多少次之内回复一次】
          </div>
          <div>
            <el-input
              v-model="keywordInfo.frequency"
              placeholder="请输入次数，数字"
              type="number"
            ></el-input>
          </div>
          <div><i class="t">* </i>话术脚本</div>
          <div>
            <div
              v-for="(item, index) in keyword_content"
              class="contentwords"
              :key="index"
            >
              <el-input
                placeholder="请输入话术脚本"
                v-model="item.content"
                type="textarea"
                :rows="5"
                :maxlength="500"
                show-word-limit 
              ></el-input>

              <div class="mt10">
                <el-button
                  type="success"
                  size="small"
                  plain
                  @click="audition(item.content)"
                  >预览</el-button
                >
                <el-button
                  :disabled="is_intelligence"
                  type="warning"
                  size="small"
                  plain
                  @click="intelligence(index, 'keyword_content')"
                  >智能重写</el-button
                >
                <SystemKeyword @handleClick="handleClick3($event,index)" small="small" class="ml10"/>
                <el-button
                  @click="keyword_content.splice(index, 1)"
                  v-if="index > 0"
                  icon="el-icon-delete"
                  plain
                ></el-button>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px">
            <el-button type="primary" plain class="add_text" @click="add_keyrods_line"  size="small">新增一行话术</el-button>
          </div>
          <div class="title"><i class="t"></i>绿幕视频地址（注意：电脑本地路径，使用双斜线\\分割，请手动更改） </div>
          <div style="display: flex" v-for="(item,g) in green_path_list" :key="g"    class="mb10">
            <el-button v-if="g > 0" @click="green_path_list.splice(g,1)" type="danger" icon="el-icon-delete" plain size="small"></el-button>
            <el-input
            placeholder="格式例如：C:\\Users\\Administrator\\Desktop\\绿幕.mp4"
            v-model="item.template_content"
            maxlength="100"   
          ></el-input> 
          </div>
          <el-button @click="green_path_list.push({template_content:''})" class="mb10" type="primary" icon="el-icon-plus" plain size="small">添加地址</el-button>
          <div class="title"><i class="t"></i>商品ID(前往客户端获取商品列表复制对应id) </div>
          <el-input
            placeholder=""
            v-model="promotion_id"
            maxlength="100"   
          ></el-input> 
          <el-button
            type="info"
            style="width: 100%; background-color: black; color: #fff"
            @click="addKeywords"
            >提交</el-button
          >
        </div>
      </el-dialog>

      <!-- 快捷回复添加 -->
      <el-dialog
        title="快捷回复"
        :modal="false"
        :visible.sync="quickVisible"
        width="80%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="keywords">
          <div><i class="t">* </i>模块名称</div>
          <div>
            <el-input
              placeholder="请输入模块名称"
              v-model="quick_name"
              :maxlength="20"
            ></el-input>
          </div>
          <div><i class="t">* </i>话术脚本</div>
          <div>
            <div
              v-for="(item, index) in quick_info_list"
              class="contentwords"
              :key="index"
            >
              <el-input
                placeholder="请输入脚本内容,最多500字"
                v-model="item.content" 
                type="textarea"
                :rows="4"
                show-word-limit
                :maxlength="500"
              ></el-input>
              <div class="mt10">
                <el-button
                  type="success"
                  size="small"
                  plain
                  @click="audition(item.content)"
                  >预览</el-button
                >
                <el-button
                  :disabled="is_intelligence"
                  type="warning"
                  size="small"
                  plain
                  @click="intelligence(index, 'quick_info_list')"
                  >智能重写</el-button
                >
                <SystemKeyword @handleClick="handleClick4($event,index)" small="small" class="ml10"/>
                <el-button
                  @click="quick_info_list.splice(index, 1)"
                  v-if="index > 0"
                  size="small"
                  icon="el-icon-delete"
                  plain
                ></el-button>
              </div>
            </div>
          </div>
          
          <div style="margin-top: 10px">
            <el-button type="primary" plain class="add_text"  size="small" @click="add_quick">新增一行话术</el-button>
          </div>
          <div class="title"><i class="t"></i>绿幕视频地址（注意：电脑本地路径，使用双斜线\\分割，请手动更改） </div>
          <div style="display: flex" v-for="(item,g) in green_path_list" :key="g"    class="mb10">
            <el-button v-if="g > 0" @click="green_path_list.splice(g,1)" type="danger" icon="el-icon-delete" plain size="small"></el-button>
            <el-input
            placeholder="格式例如：C:\\Users\\Administrator\\Desktop\\绿幕.mp4"
            v-model="item.template_content"
            maxlength="100"   
          ></el-input> 
          </div>
          <el-button @click="green_path_list.push({template_content:''})"  class="mb10" type="primary" icon="el-icon-plus" plain size="small">添加地址</el-button>
          <div class="title"><i class="t"></i>商品ID(前往客户端获取商品列表复制对应id) </div>
          <el-input
            placeholder=""
            v-model="promotion_id"
            maxlength="100"   
          ></el-input> 
          <div><i class="t">* </i>回复类型</div>
          <div>
            <el-radio-group v-model="quick_type">
              <el-radio :label="5">弹幕</el-radio>
              <el-radio :label="1">进入直播间</el-radio>
              <el-radio :label="2">点关注</el-radio>
              <el-radio :label="3">送礼物</el-radio>
              <el-radio :label="4">点赞</el-radio>
            </el-radio-group>
          </div>
          
          <el-button
            style="background-color: black; color: #fff; width: 100%"
            @click="submit_quick(1)"
            >提交</el-button
          >
        </div>
      </el-dialog>
      <!-- <SoundAudio /> -->
      <Handbook />
    </div>
  </el-drawer>
</template>
<script>
import {
  intelligenceRewrite,
  createQuick,
  speechModel,
  getQuickList,
  editQuick,
  delQuick,
  creatScript,
  scriptList,
  scriptInfo,
  scriptDel,
  scriptEdit,
  creatProjectPersion,
  getVersionInfo,
  editVersionInfo,
  delVersion,
  createKeywords,
  getkeywordList,
  editkeywordList,
  delkeyword,
} from "@/api/index";
import { getLocalStorage } from "@/utils/storage";
import SystemKeyword from "@/views/components/systemKeyword.vue"; //系统变量
import Handbook from "@/components/handbook.vue";
import { textformat,gettime } from "@/utils/util";
export default {
  components: {
    SystemKeyword,
    Handbook,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    // 项目id名称
    id: {
      type: String,
      default: "",
    },
    project_name: {
      type: String,
      default: "",
    },
  },
  
  data() {
    return {
      searchText: "",
      value1: "选择版本",
      value2: "选择声音",
      script_defualt_value: "选择模块",
      drawerStu: this.drawer,
      active: 0,
      actives: [
        { index: 0, title: "整体脚本", tips: "编辑整体脚本信息" },
        { index: 1, title: "话术模板", tips: "设置模块话术内容" },
        { index: 2, title: "关键词自动回复", tips: "设置关键词自动回复模板" },
        { index: 3, title: "快捷回复列表", tips: "创建快捷回复信息" },
      ],
      // 声音模型
      AudioList: [],
      checkAudioModel: "",
      // 模块id
      project_id: "",
      promotion_id:'',
      // 点击的模块名称
      script_name: "",
      // 是否副播
      is_replay: 0,
      dialogVisible: false,
      edit_Visible: false,
      green_path_list:[],
      // 项目列表
      script_list: [],
      script_list_copy: [],
      sentence_list: [
        {
          template_content: "",
        },
      ],
      script_info_list: [],
      // 版本名字
      version: "",
      entirety_script: "",
      // 脚本内容
      entirety_script_handle: "",
      // 版本列表
      versionData: [],
      // 默认当前版本信息，或者已选择版本信息
      versionInfo: "",
      // 脚本预览文本
      tipstext: "",
      // 关键词
      keywordsVisible: false,
      keywordInfo: {
        keyword_name: "",
        keyword_issue: [],
        frequency: "",
      },
      keyid: "",
      keyword_content: [{ content: "" }],
      keywordList: [],
      keyword_issue: [],
      value: "",
      // 快捷回复
      quickVisible: false,
      quick_id: "",
      quick_name: "",
      quick_info_list: [{ content: "" }],
      quickList: [],
      quick_type: 5,
      // 智能重写按钮防多次点击
      is_intelligence: false,
    };
  },
  async mounted() {
    this.refresh();
  },
  methods: {
    getLength(arr){   
      if(typeof arr == 'string'){
        let arr1 = JSON.parse(arr)
        const hasTemplateContent = arr1.every((item) => item.template_content);
        if(!hasTemplateContent){
          return false
        }
      } 
      if(arr == '' || !arr || arr.length < 5){
        return false
      } 
      return true
    },
    addAllModel(){ 
      for(let i in this.script_list){
        this.entirety_script_handle += `{${this.script_list[i].script_name}}`;
      }
    },
    // 获取模型声音
    async getSpeechModel() {
      const res = await speechModel(); 
      this.AudioList = res;
      this.checkAudioModel = res[0];
      this.$store.commit("setAudioList", res);
      this.$store.commit("refreshAudioInfo", res[0]);
    },
    // 关键词同步到快捷回复
    synchronization(index) {
      let data = this.keywordList[index];
      this.quick_name = data.keyword_name;
      let data2 = JSON.parse(data.keyword_content);
      for (let i in data2) {
        data2[i] = {
          content: data2[i],
        };
      }

      this.quick_info_list = data2;
      this.submit_quick(2);
    },
    // 模块搜索筛选
    searchClick() {
      let script_list = this.script_list_copy;
      let value = this.searchText;
      let new_script_list = [];
      for (let i in script_list) {
        if (script_list[i].script_name.indexOf(value) != -1) {
          new_script_list.push(script_list[i]);
        } else {
          let arr = JSON.parse(script_list[i].script_list);
          for (let j in arr) {
            if (arr[j].template_content.indexOf(value) != -1) {
              new_script_list.push(script_list[i]);
            }
          }
        }
      }
      this.script_list = new_script_list;
    },
    handbookShow() {
      this.$store.commit("setHandbookShow", true);
    },
    // 添加脚本系统变量
    handleClick(e) {
      // let data = e.run()
      this.entirety_script_handle = this.entirety_script_handle + `[${e.text}]`;
    },
    handleClick2(e, index) {
      let data = this.sentence_list;
      data[index].template_content = data[
        index
      ].template_content += `[${e.text}]`;
      this.sentence_list = data;
    },
    handleClick3(e, index) {
      let data = this.keyword_content;
      data[index].content = data[index].content += `[${e.text}]`;
      this.keyword_content = data;
    },
    handleClick4(e, index) {
      let data = this.quick_info_list;
      data[index].content = data[index].content += `[${e.text}]`;
      this.quick_info_list = data;
    },
    // 刷新
    refresh() {
      this.getScriptList();
      // this.getSpeechModel();
      this.getVisionInfo();
      this.get_keyword_list();
      this.get_quick_list();
      let modelList = getLocalStorage("AudioList");
      this.AudioList = modelList;
      this.checkAudioModel = modelList[0];
      this.value2 = modelList[0].name;
    },
    // 智能重写
    async intelligence(index, arrName) {
      let item = this[arrName][index];
      let text = "";
      if (arrName == "sentence_list") {
        text = item.template_content;
      } else if (arrName == "keyword_content") {
        text = item.content;
      } else if (arrName == "quick_info_list") {
        text = item.content;
      }
      if (!text) return this.$message.error("请输入要重写的文字");

      this.is_intelligence = true;
      const res = await intelligenceRewrite({ text });
      if (res.state == 1) {
        if (arrName == "sentence_list") {
          this[arrName].push({
            template_content: res.data,
          });
        } else if (arrName == "keyword_content") {
          this[arrName].push({
            content: res.data,
          });
        } else if (arrName == "quick_info_list") {
          this[arrName].push({
            content: res.data,
          });
        }
        this.is_intelligence = false;
        return;
      }
      setTimeout(() => {
        this.is_intelligence = false;
      }, 2000);
    },
    drawerClose(e) {
      this.$emit("open");
    },
    // 删除快捷回复
    async delete_quick(id) {
      const that = this;
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(async () => {
        const res = await delQuick(id);

        if (res.state == 1) {
          that.get_quick_list();
          return that.$message.success("删除成功！");
        }
        return false;
      });
    },
    // 快捷回复列表
    async get_quick_list() {
      const res = await getQuickList(this.id);
      if (res.state == 1) {
        this.quickList = res.data;
      }
    },
    edit_quick(index) {
      let as = this.quickList[index];
      let data = JSON.parse(as["quick_content"]);
      let arr = [];
      for (let i in data) {
        arr.push({
          content: data[i],
        });
      }
      this.quick_info_list = arr;
      this.quick_name = as.quick_name;
      this.quick_id = as.id;
      this.quick_type = as.type; 
      this.promotion_id = as.promotion_id;
      let path_list = JSON.parse(as["green_path_list"]) || []
      const result = path_list.filter((item) => {
        const values = Object.values(item);
        return values.length > 0 && values[0] !== "";
      });
      if(result.length > 0){
        this.green_path_list = result
      }else{
        this.green_path_list = []
      } 
      this.quickVisible = true;  
    },
    // 添加编辑快捷回复
    submit_quick(is_async) {
      if (!this.quick_name) return this.$message("请输入名称");

      let arr = [];
      for (let i in this.quick_info_list) {
        arr.push(this.quick_info_list[i].content);
      }
      if (arr.length == 0) return this.$message("请添加快捷回复内容"); 
   
      let data = {
        project_id: this.id,
        quick_name: this.quick_name,
        quick_content: JSON.stringify(arr),
        type: this.quick_type,
        green_path_list:JSON.stringify(this.green_path_list),
        promotion_id:this.promotion_id
      };
      const that = this;
      if (this.quick_id) {
        this.$confirm("确认提交编辑吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(async () => {
          data.quick_id = that.quick_id;
          const res = await editQuick(data);

          if (res.state == 1) {
            that.quickVisible = false;
            that.quick_id = "";
            that.quick_info_list = [{ content: "" }];
            that.quick_name = "";
            that.quick_type = 5;
            that.promotion_id = ''
            that.get_quick_list();
            return that.$message.success("保存成功！");
          }
          return false;
        });
      } else {
        let text = "确认添加吗?";
        if (is_async == 2) {
          text = "确认同步到快捷回复列表吗？";
        }
        this.$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(async () => {
          const res = await createQuick(data);

          if (res.state == 1) {
            that.quickVisible = false;
            that.quick_name = "";
            that.quick_info_list = [{ content: "" }];
            that.quick_type = 5;
            that.promotion_id = ''
            that.get_quick_list();
            return that.$message.success("添加成功！");
          }
          return false;
        });
      }
    },
    // 新增一行快捷回复
    add_quick() {
      this.quick_info_list.push({
        content: "",
      });
    },
    add_keys(e) {
      if (e.keyCode == 13 && this.value != "") {
        this.keyword_issue.push(this.value);
        this.value = "";
      }
    },
    keywordsVisibleShow() {
      this.keywordInfo = {
        keyword_issue: [],
        frequency: "",
      };
      this.keyid = "";
      this.keyword_content = [{ content: "" }];
      this.keyword_issue = [];
      this.keywordsVisible = true;
      this.green_path_list = [];
      this.promotion_id = ''
    },
    quickVisibleShow() {
      this.quick_name = "";
      this.quick_info_list = [{ content: "" }];
      this.quick_id = "";
      this.quickVisible = true;
      this.green_path_list = []
        this.promotion_id = ''
    },
    edit_keywords(index) {
      let data = JSON.parse(this.keywordList[index]["keyword_content"]);
      let keyword_issue = JSON.parse(this.keywordList[index]["keyword_issue"]);
      let arr = [];
      for (let i in data) {
        arr.push({
          content: data[i],
        });
      } 
      this.promotion_id = this.keywordList[index]['promotion_id']
      this.keyword_content = arr;
      this.keywordInfo = {
        keyword_name: this.keywordList[index]["keyword_name"],
        keyword_issue: this.keywordList[index]["keyword_issue"],
        frequency: this.keywordList[index]["frequency"],
      };
      this.keyword_issue = keyword_issue;
      this.keyid = this.keywordList[index].id;
      this.keywordsVisible = true;

      let path_list = JSON.parse(this.keywordList[index]["green_path_list"]) || []
      const result = path_list.filter((item) => {
        const values = Object.values(item);
        return values.length > 0 && values[0] !== "";
      });
      if(result.length > 0){
        this.green_path_list = result
      }else{
        this.green_path_list = []
      }   
    },
    // 获取关键词列表
    async get_keyword_list() {
      const res = await getkeywordList(this.id);
      this.keywordList = res.data;
    },
    // 新增一行关键词回复内容
    add_keyrods_line() {
      this.keyword_content.push({
        content: "",
      });
    },
    // 删除关键词
    async delete_keywords(id) {
      const that = this;
      this.$confirm("确认提交编辑吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(async () => {
        const res = await delkeyword(id);

        if (res.state == 1) {
          that.get_keyword_list();
          return this.$message.success("删除成功！");
        }
        return false;
      });
    },
    // 添加关键词
    async addKeywords() {
     
      if (!this.keywordInfo.keyword_issue) return this.$message("请输入关键词");
      if (!this.keywordInfo.frequency) return this.$message("请输入回复频率");
      if (this.keyword_content.length == 0)
        return this.$message("请添加回复内容");
      let arr = [];
      for (let i in this.keyword_content) {
        arr.push(this.keyword_content[i].content);
      }
      if (this.keyword_issue.length == 0)
        return this.$message("请添加至少一个关键词");
      const data = {
        project_id: this.id,
        ...this.keywordInfo,
        keyword_content: JSON.stringify(arr),
        green_path_list:JSON.stringify(this.green_path_list),
        promotion_id:this.promotion_id
      };
      data.keyword_issue = JSON.stringify(this.keyword_issue);
      const that = this;
      if (this.keyid) {
        this.$confirm("确认提交编辑吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(async () => {
          data.key_id = that.keyid;
          const res = await editkeywordList(data);

          if (res.state == 1) {
            that.keywordsVisible = false;
            that.keywordInfo = {
              keyword_issue: "",
              frequency: "",
            };
            that.keyid = "";
            that.promotion_id = ''
            that.keyword_content = [{ content: "" }];
            that.get_keyword_list();
            return that.$message.success("添加成功！");
          }
          return false;
        });
      } else {
        this.$confirm("确认添加吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(async () => {
          const res = await createKeywords(data);

          if (res.state == 1) {
            that.keywordsVisible = false;
            that.keywordInfo = {
              keyword_issue: "",
              frequency: "",
            };
            that.keyword_content = [{ content: "" }];
            that.promotion_id = ''
            that.get_keyword_list();
            return that.$message.success("添加成功！");
          }
          return false;
        });
      }
    },
    // 预生成 脚本整体试听
    auditionAudio(n) {
     
      let info = this.formatS_A();
      let arr = []; 
      for (let i in info) {
        if (typeof info[i] == "string") {
          // arr.push(info[i])
          arr.push(textformat(info[i]));
        } else {
          if (info[i].script_list.length > 0) {
            let script_list = JSON.parse(info[i].script_list);
            let len = script_list.length;
            let m = this.getRandomInt(0, len - 1);
            let text = script_list[m].template_content;
            // arr.push(text) 
            arr.push(textformat(text));
          }
        }
      }
      let tipstext = "";
      for (let i in arr) {
        tipstext += `${arr[i]}`;
      }
      this.tipstext = tipstext; 
      if (n) {
        return this.$message('保存版本，前往客户端试听')
        this.$store.commit("setLisenText", arr);
        this.$store.commit("AudioPlayShow", true);
      }
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    // 删除版本
    delete_version() {
      const that = this;
      this.$confirm("确认要删除此版本吗谨慎操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(async () => {
        const res = await delVersion(that.versionInfo.version_id);
        if (res.state == 1) {
          that.getVisionInfo();
          that.version = "";
          that.value1 = "";
          return this.$message.success("删除成功！");
        }
        return false;
      });
    },
    // 编辑版本详情
    async edit_version_info() {
      if (!this.entirety_script_handle)
        return this.$message.error("请填写脚本内容");
      const that = this;
      this.$confirm("确认提交编辑?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(async () => {
        const res = await editVersionInfo({
          version_id: that.versionInfo.version_id,
          script_content: JSON.stringify(that.formatS_A()),
        });

        if (res.state == 1) {
          that.getVisionInfo();
          that.version = "";
          return this.$message.success("保存成功！");
        }
        return false;
      });
    },
    // 切换版本
    checkVersion(index) {
      let data = this.versionData[index];
      this.entirety_script_handle = this.formatA_S(data.script_content);
      this.versionInfo = data;
    },
    // 获取项目版本详情
    async getVisionInfo() {
      const res = await getVersionInfo(this.id);
      this.versionData = res.data;
      if (res.data.length > 0) {
        this.versionInfo = res.data[0];
        this.value1 = res.data[0].template_version;
        this.entirety_script_handle = this.formatA_S(
          res.data[0].script_content
        );
      } else {
        this.versionInfo = "";
      }
    },
    checkAudioClick(index) {
      this.checkAudioModel = this.AudioList[index];
      this.$store.commit("refreshAudioInfo", this.AudioList[index]);
    },
    // 试听
    audition(content) {  
        this.$message.success({
          message:textformat(content),
          duration:0,
          showClose:true,
          repeatNum:1,
          grouping:true
      });
      return
      if (content == "") {
        return this.$message.error("请输入要转换的文字！");
      }
      this.$store.commit("AudioPlayShow", true);
      this.$store.commit("setLisenText", textformat(content));
    },

    checkAudio() {
      this.$store.state.AudioShow = true;
    },
    new_temp() {
      this.is_replay = 0;
      let len = this.script_list.length; 
      this.script_name = `话术${len + 1}`;
      this.sentence_list = [
        {
          template_content: "",
        },
      ];
      this.project_id = "";
      this.dialogVisible = true;
      this.promotion_id = ''
      this.green_path_list = [];
    },
    // 模板列表
    async getScriptList() {
      const res = await scriptList({ project_id: this.id });
      if (res.state == 1) {
        this.script_list = res.data;
        this.script_list_copy = res.data;
      }
    },
    // 编辑模板
    async edit_project(index) {
      const data = this.script_list[index];
      const res = await scriptInfo(data.id);
      data.list = JSON.parse(res.data.script_list); 
      let path_list = JSON.parse(res.data["green_path_list"]) || []
      const result = path_list.filter((item) => {
        const values = Object.values(item);
        return values.length > 0 && values[0] !== "";
      });
      if(result.length > 0){
        this.green_path_list = result
      }else{
        this.green_path_list = []
      }  
      this.is_replay = data.is_replay ? 1 : 0;
      this.project_id = data.id;
      this.promotion_id = data.promotion_id;
      this.sentence_list = data.list;
      this.script_name = data.script_name;
      this.dialogVisible = true;
    },
    imgUpload(e){
      let file = e.target.files[0]; 
      var fileReader = new FileReader(); 
      fileReader.onload = function(event) {
        var dataUrl = event.target.result;
        console.log(event);
        document.getElementById('preview').src = dataUrl;
      }; 
      fileReader.readAsDataURL(file);
    },
     // 添加模板
    async submit_add() {
   
      let list = this.sentence_list
     
      for (let i in list) {
        if (!list[i].template_content) {
          return this.$message("有空白的话术未填写");
        }
      }
      if (!this.project_id) {
        let data = {
          is_replay: this.is_replay ? true : false,
          project_id: this.id,
          script_name: this.script_name,
          script_list: JSON.stringify(list),
          green_path_list:JSON.stringify(this.green_path_list),
          promotion_id:this.promotion_id
        };
    
        const res = await creatScript(data);
        if (res.state == 1) {
          this.$message("添加成功!");
          this.script_name = "";
          this.promotion_id = ''
          this.sentence_list = [
            {
              template_content: "", 
            },
          ];
          this.green_path_list = []
          this.getScriptList();   
          this.dialogVisible = false;
          this.is_replay = 0;
        }
      } else {
        this.submit_edit();
      }
    },
    // 删除模块
    delete_script(id) {
      const that = this;
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async () => {
          const res = await scriptDel(id);
          if (res.state == 1) {
            that.getScriptList();
          }
          return false;
        })
        .catch(() => {});
    },
    // 模块提交编辑
    submit_edit() {
      for (let i in this.sentence_list) {
        if (!this.sentence_list[i].template_content) {
          return this.$message("有空白的话术未填写");
        }
      }
      if (!this.script_name) {
        return this.$message("请填写模块名称");
      }
      let formData = {
        script_id: this.project_id,
        script_name: this.script_name,
        is_replay: this.is_replay ? true : false,
        script_list: JSON.stringify(this.sentence_list),
        green_path_list:JSON.stringify(this.green_path_list),
        promotion_id:this.promotion_id
      };
      const that = this;
      this.$confirm("确认提交编辑吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(async () => {
        const res = await scriptEdit(formData);

        if (res.state == 1) {
          that.dialogVisible = false;
          that.getScriptList();
          that.getVisionInfo()
          return this.$message.success("编辑成功！");
        }
        return false;
      });
    },
    // 新增一行话术
    add_content() {
      this.sentence_list = this.sentence_list.concat({
        template_content: "",
        // script_url: ''
      });
    },
    command_script(index) {
      let data = this.script_list[index];
      this.entirety_script_handle =
        this.entirety_script_handle + `{${data.script_name}}`;
      this.script_defualt_value = "选择模块";
    },
    //提交新版本
    submit_add_version() {
      if (!this.version) return this.$message.error("请输入新的版本号");

      let result = this.formatS_A();
      if (result.length == 0 || JSON.stringify(result).length <= 0)
        return this.$message.error("请编辑脚本");
      let that = this;
      this.$confirm("保存为新版本?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async () => {
          const res = await creatProjectPersion({
            project_id: that.id,
            version_name: that.version,
            script_content: JSON.stringify(result),
          });

          if (res.state == 1) {
            that.getVisionInfo();
            that.version = "";
            return this.$message.success("保存成功！");
          }
          return false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "保存失败",
          });
        });
    },
    // 脚本文本格式处理, 字符串转数组
    formatS_A() {
      let b = this.script_list;
      let regex = /\{(.*?)\}|(.*?)(?=\{|$)/g;
      let a = this.entirety_script_handle;
      // let arr = a.match(regex).map(s => s.includes('|') ? s : s.replace(/\{|\}/g, ''));
      let arr = a.match(regex).map((s) => s.replace(/\{|\}/g, ""));
 
      arr = arr.filter((item) =>{
        let str = item.trim() 
        return str != ''
      }); 
      let result = arr.map(function (item) {
        return (
          b.find(function (obj) {
            return obj.script_name === item;
          }) || item
        );
      });
      return result;
    },
    // 脚本文本格式处理, 数组转字符串
    formatA_S(data) {
      let str = "";
      let list = JSON.parse(data);
      if (data.length > 0) {
        for (let i in list) {
          if (typeof list[i] == "string" || typeof list[i] == "String") {
            str += list[i];
          } else {
            // str += `&${list[i].script_name}&`
            str += `{${list[i].script_name}}`;
          }
        }
        return str;
      }
      return "";
    },
    activeClick(index) {
      this.active = index;
    },
  },
};
</script>
<style lang="scss">
#edit {
  .el-textarea__inner,
  .el-input__inner {
    letter-spacing: 1px !important;
  }
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: $dk;
}

.el-drawer__header {
  display: none !important;
}

.version {
  .el-button {
    padding: 9px 10px !important;
    font-size: 12px;
  }
}
.edit_dialog,
.keywords {
  input,
  textarea {
    background-color: #f9f9f9 !important;
  }
  .add_text {
   
  }
}
.script-main .el-textarea__inner {
  color: #409eff;
}
</style>
<style lang="scss" scoped>
.btn_active {
  background-color: #000;
  color: #fff;
}

#edit {
  padding: 10px;
  display: flex;

  .main {
    flex: 1;

    .tabs_main {
      padding: 10px 0;
      overflow: hidden;
    }

    .tabs {
      overflow: hidden;

      .active {
        background: #000;
        color: #fff !important;

        .t2 {
          color: #fff !important;
        }
      }

      > div {
        width: 180px;
        height: 66px;
        border-radius: 5px;
        background: #eeeeee;
        float: left;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 16px;
        font-weight: 800;
        font-size: 17px;

        &:hover {
          cursor: pointer;
        }

        .t2 {
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #7e7e7e;
          margin-top: 5px;
        }
      }
    }

    .script-s {
      display: flex;
      margin-top: 20px;

      .script-main {
        flex: 1;
        background-color: $bgc;
        padding: 10px;

        .script {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
      }

      .tips {
        margin-left: 20px;
        width: 300px;
        // overflow: scroll;
        font-size: 16px;
        background-color: $bgc;
      }
    }
  }

  .func {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 80px;
    justify-content: space-between;

    .func_r {
      display: flex;

      .version {
        display: flex;
        align-items: center;
        margin-right: 20px;

        h4 {
          margin-right: 10px;
        }
      }
    }
  }

  .tipHtml {
    width: 300px;
    padding: 10px;
    margin-left: 10px;
    box-shadow: 0px 4px 10px #f1f1f1;
    background-color: $bgc;

    h3 {
      font-size: 16px;
    }

    > div {
      padding: 10px 0;
    }
    .infinite-list-item {
      letter-spacing: 2px;
    }
  }

  .add {
    position: fixed;
    right: 50px;
    bottom: 100px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #3b65bb;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    i {
      display: block;
      font-size: 20px;
    }
  }

  .temp_list {
    box-shadow: 0px 0px 5px #ccc;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 300px;
    height: 164px;
    float: left;
    border-radius: 5px;
    position: relative;
    .t3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      color: #86909c;
      margin: 10px 0;
    }

    h3 {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 800;
      font-size: 17px;
    }
    .temp_title {
      display: flex;
      justify-content: space-between;

      .ts {
        width: 42px;
        height: 22px;
        border-radius: 2px;
        opacity: 1;
        border: 1px solid #a6c2ec;
        color: #a6c2ec;
        text-align: center;
        line-height: 22px;
      }
    }

    .setting {
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      // margin-top: 20px;
      background-color: #fff;
    }
  }

  .edit_dialog {
    .t {
      color: red;
      margin-right: 5px;
    }

    .title {
      margin: 10px 0;
    }

    .del_content {
      margin-bottom: 10px;
      background-color: #eaeaea;
      padding: 3px;
      border: 1px solid #ccc;
    }

    .title1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .audio_check {
      display: flex;
      margin: 20px 0 40px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .edit_temp {
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .temp_name {
        display: flex;
        align-items: center;

        i {
          width: 100px;
        }
      }
    }

    .lists {
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
      background-color: #faf8f8;

      h3 {
        margin-bottom: 10px;
      }

      .buttons {
        margin: 20px 0;
      }
    }
  }

  .keywords {
    > div {
      margin-bottom: 20px;

      i {
        color: red;
      }
    }

    .contentwords {
      margin-bottom: 10px;
      background-color: #eaeaea;
      padding: 3px;
      border: 1px solid #ccc;
      i {
        width: 20px;
      }
    }
  }
}
</style>
