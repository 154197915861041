import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import { Message } from 'element-ui';
import {delLocalStorage } from '@/utils/storage'
 
const instance = axios.create({
  timeout: 120000,
  baseURL: store.state.baseURL, 
  // 跨域请求时允许携带凭证（cookie）
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});
 
//请求拦截
instance.interceptors.request.use(

  //接受两个函数作为参数
  config => {   
    // config.headers['Content-Type'] = "application/x-www-form-urlencoded" 
    if(store.state.token) config.headers['Authorization'] =  store.state.token
 
    return config;
  },
  error => {

    return Promise.reject(error);
  }
);

//响应拦截
instance.interceptors.response.use(
  async response => { 
    const msg = response.data.message
    const state = response.data.state
    if(response.config.url == '/chat/chat' || response.config.url == '/v1/template/audition/')  return response.data   
    if (msg == '用户未登录' || msg == 'token认证失败' || response.data.code == 401) {
      store.state.token = ''
      store.state.userInfo = ''
      delLocalStorage('token')
      delLocalStorage('userInfo') 
      Message(msg)
      return
    }    
    if (state != 1) {
      Message(msg) 
    } 
    return response.data
  },
  error => {
    Message('请求超时！') 
    return Promise.reject(error);
  }
);

//get请求 ,获取
export const get = (url, config) => instance.get(url, config);

//post请求，,增加 查
export const post = (url, data, config) => instance.post(url, data, config);

// put请求，改
export const put = (url, data, config) => instance.put(url, data, config);

//delete请求，删
export const del = (url, data, config) => instance.delete(url, data, config);


