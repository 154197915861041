<template>
  <div id="tabbar">
    <div class="logo">
      <i class="img">
        <img class="p1" src="@/assets/p1.png" alt="" />
      </i>
      <h1>AI直播管理后台</h1>
    </div>
    <div class="setting">
      <!-- <i class="el-icon-user-solid" ></i> -->
      <i class="userInfo">欢迎 【{{ userInfo.username }}】 ~</i>
      <i class="mr20" style="font-size: 14px;">{{ userInfo.datetime_overdue}}到期</i>
      <el-button
        size="small"
        type="info"
        @click="loginOut"
        style="background-color: black; color: #fff"
        >退出</el-button
      >
    </div>
  </div>
</template>
<script>
import { delLocalStorage } from "@/utils/storage";
export default {
  name: "tabbar",
  data() {
    return {
      userInfo: this.$store.state.userInfo,
    };
  },
  mounted() {},
  methods: {
    loginOut() {
      this.$confirm(`请保存好正在编辑的项目，确认退出吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async ({ value }) => {
          this.$store.commit("loginOut");
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
#tabbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  height: 64px;
  align-items: center;
  border-bottom: 2px solid $bgc;

  .logo {
    display: flex;
    align-items: center;
    .img {
      background: linear-gradient(180deg, #c1559d 0%, #6730c7 99%);
      width: 32px;
      height: 32px;
      border-radius: 9px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .p1 {
        width: 18px;
        height: 18px;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: 0em;
      color: #333333;
      margin-left: 10px;
    }
  }
  .setting {
    font-size: 16px;
    .userInfo {
      margin-right: 20px;
    }
  }
}
</style>
