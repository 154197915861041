<template>
  <div id="app" v-if="token">
    <!-- <img class="aiComponent" src="@/assets/3.png" alt=""  @click="drawerStu = !drawerStu"/> -->
    <div class="tabbar">
      <TabBar />
      <Audio />
    </div>
    <div class="main">
      <div class="aside">
        <Aside />
      </div>
      <div class="home">
        <router-view />
      </div>
    </div>

    <el-drawer
      :visible.sync="drawerStu"
      size="40%" 
      :modal="true" 
    >
      <AiComponent />
    </el-drawer>
  </div>
  <div v-else class="login">
    <Login />
  </div>
</template>
<script>
import TabBar from "@/components/tabbar.vue";
import Aside from "@/components/aside.vue";
import Login from "@/components/login.vue";
import Audio from "@/components/audio.vue";
import AiComponent from "@/views/ai.vue";
import { getLocalStorage } from "@/utils/storage";
export default {
  components: {
    TabBar,
    Aside,
    Login,
    Audio,
    AiComponent
  },
  data() {
    return {
      token: this.$store.state.token,
      drawerStu:false
    };
  },
  watch: {
    "$store.state.token": function (nvalue, old) {
      this.token = nvalue;
    },
  },
  mounted() {
    if (getLocalStorage("token")) {
      this.$store.commit("AllInRefresh");
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  .aiComponent {
    position: fixed;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 5px #ccc;
    z-index: 999999999;
    cursor: pointer;
    background: #fff;
    animation: jump 1s infinite;
  }
  @keyframes jump {
    0%,
    100% {
      top: 10px;
    }
    50% {
      top: 20px;
    }
  }
  .tabbar {
    height: 80px;
    background-color: $dk;
  }

  .main {
    flex: 1;
    display: flex;

    .home {
      flex: 1;
      height: calc(100vh - 64px);
      overflow: scroll;
    }
  }
}

.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
