<template>
  <div id='audio'>
      <el-dialog title="声音播放" :modal="false" :visible.sync="AudioPlayShow" width="50%" :before-close="handleClose"
          :close-on-click-modal="false" :modal-append-to-body="false">
          <audio :src="LisenUrl" ref="audios" controls controlsList="nodownload" @ended="playEnded"></audio>
          <h2>正在生成试听语音，请稍等片刻，脚本文字加载完毕即可播放~</h2>
         
          <br>
         
          <div class="tips"> 
              <h3>脚本文字：</h3>
              <i v-for="(item, index)  in textList" :class="playIndex == index ? 'isred' : ''" :key="index">{{ item }}</i>
          </div>
      </el-dialog>
  </div>
</template>
<script>
/**
* 播放列表
* 每次请求都向播放列表中插入行的播放链接
* 依次播放
* 关闭页面清空播放列表
*/
import { uploadFiles } from '@/api/index'
import {textformat} from '@/utils/util'
import {mapState} from 'vuex'
export default {
  data() {
      return {
          LisenUrl: '',
          LisenUrlList: [],
          playIndex: 0,
          LisenText: '',
          AudioPlayShow: false,
          textList: [],
          isplay:false
      }
  },
  computed:{
    ...mapState(['token'])
  },
  watch: {
      '$store.state.audioPlayShow': function (n, o) {
          this.AudioPlayShow = n
          if (!n) this.handleClose()
      },
      '$store.state.LisenText': async function (n, o) {
          this.LisenText = n
          if (n) { 
              this.getLinsen(n)
          }

      },
  },
  mounted() {
      this.handleClose()
  },
  methods: {
     
      playAudio() {
          if (!this.LisenUrl) {
              this.LisenUrl = this.LisenUrlList[0]
          }
          setTimeout(() => {
              this.$refs.audios.play()
          }, 20);
      },
      stopAudio() {
          this.$refs.audios.pause()
      },
      playEnded() {
          // 播放列表数量3
          let len = this.LisenUrlList.length
          this.playIndex = this.playIndex + 1
          this.LisenUrl = this.LisenUrlList[this.playIndex]
          if (this.playIndex >= len) {
              this.isplay = false
              this.stopAudio()
              return
              // this.playIndex = 0
              // this.LisenUrl = this.LisenUrlList[0]
          }
          if (this.LisenUrl) this.playAudio()
      },
      async getLinsen(text) {
      
          if (typeof text == 'string') {
           
              let str =  textformat(text)
              
              this.textList.push(str)
              // if(str == 'stop'){
              //     return
              // }
              const res = await uploadFiles({
                  model_id: this.$store.state.AudioInfo.model_id,
                  text:  str,
                  token:this.token
              }) 
              if (res?.size < 200) {
                  return this.$message('语音生成失败！')
              }
              if (res) {
                  const url = URL.createObjectURL(res) 
                  if (url && this.AudioPlayShow) {
                      this.LisenUrlList.push(url)
                      this.playAudio()
                  }
              }
               
          } else {
        
              for (let i = 0; i < text.length; i++) { 
                  let typeAudio = textformat(text[i])
                  this.textList.push(typeAudio)
                  const res = await  uploadFiles({
                      model_id: this.$store.state.AudioInfo.model_id,
                      text:typeAudio,
                      token:this.token
                  })
                
                  if (res?.size < 200) {
                      return this.$message('语音生成失败！')
                  }
                  if (res) {
                      const url = URL.createObjectURL(res) 
                      if (url && this.AudioPlayShow) {
                          this.LisenUrlList.push(url)
                      }
                  }
                  if(!this.isplay && this.LisenUrlList.length > this.textList.length / 3 ){
                      this.isplay = true
                      this.playAudio()
                  } 
              } 
              if(!this.isplay)  this.playAudio()
             
          }


      }, 
      handleClose() {
          this.$store.commit('AudioPlayShow', false)
          this.LisenUrl = ''
          this.LisenUrlList = []
          this.$store.commit('setLisenText', '')
          this.playIndex = 0
          this.textList = []
      }
  }
}
</script>
<style lang='scss' scoped>
.tips{
  padding: 10px;
  background-color: #e2e2e2;
  border-radius: 5px;
  margin-top: 20px;
  h2{
      margin-bottom: 10px;
  }
}
.isred {
  color: red;
  font-size: 16px;
}
</style>