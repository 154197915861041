<template>
    <div id='aside'>
        <div class="aside_main">
            <div @click="changeTab(item)" :key="index" v-for="(item,index) in tab" :class="item.index == active ? 'defaut' : ''">
                <div>
                    <i :class="item.icon"></i>
                    <span>{{ item.text }}</span>
                </div>
                <img src="@/assets/right.png" alt="">
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 0,
            tab: []
        }
    },
    mounted() {
        this.tab = this.$store.state.tabs;
        this.active = this.$store.state.tabsIndex;
        // this.$store.commit('changeTabs',this.active);  
    },
    methods: {
        // 切换tab
        changeTab(item) {
            if(!item.status) return this.$message.error('该功能暂未对外开放');
            // 切换tab
            this.active = item.index;
            this.$store.commit('changeTabs', item.index);
        }
    }
}
</script>
<style lang='scss' scoped>
#aside {
    width: 160px;
    height: 100%;
    padding: 2px;

    .aside_main {
        padding: 10px 0px;
        text-align: center;
       

        .defaut {
            background-color: #f1f1f1;
        }

        >div {
            padding: 15px 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                margin-left: 5px;
            }
            img{
                width: 15px;
            }
        }
        
    }
}</style>